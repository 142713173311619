import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import StaticBG from "../assets/images/pages/alumni-hero.jpg"
import { OutboundLink } from "gatsby-plugin-google-gtag"

export default function Alumni({ data }) {
  return (
    <Layout>

      <Seo
        title="Alumni"
        description="Scholastic Awards recipients: Welcome to our Alumni network! Learn more about opportunities available for you"
      />

<div id="alumni" className="brochure">
				<section id="brochure-hero" style={{ backgroundImage: "url(" + StaticBG + ")" }}>
					<div id="brochure-hero-overlay">
						<div className="intro">
							<h2>Alumni</h2>
							<div className="tagline">Scholastic Awards recipients: Welcome to our Alumni network! Learn more about opportunities available for you</div>
						</div>
					</div>
				</section>
				<section id="brochure-features">
					<div id="alumni-opportunities" className="feature-cover">
						<div className="image">&nbsp;</div>
						<div className="text">
							<h3><span>Alumni Opportunities</span></h3>
							<p>Apply for microgrants to further your creative development</p>
							<Link to="/alumni-opportunities" className="button">View Opportunities</Link>
						</div>
					</div>
					<div className="feature-rule"><hr /></div>
					<div id="alumni-stay" className="feature-thumb-alt">
						<div className="image">&nbsp;</div>
						<div className="text">
							<h3>Stay in Touch</h3>
							<p>Share your story and how the Awards have impacted you! Connect through our LinkedIn group, alumni meet-ups, and exhibitions</p>
							<OutboundLink href="https://www.linkedin.com/groups/4001380/" rel="noreferrer" target="_blank" className="button">Connect with Us</OutboundLink>
						</div>
					</div>
					<div id="alumni-history" className="feature-cover-alt" >
						<div className="image">&nbsp;</div>
						<div className="text">
							<h3><span>History</span></h3>
							<p>Did you know that the Awards have been around for more than 100 years? Our visionary alumni include Tschabalala Self, Stephen King, Kay WalkingStick, José Parlá, Amanda Gorman, Charles White, Joyce Carol Oates, and Andy Warhol.</p>
							<Link to="/awards/history" className="button">Learn More</Link>
						</div>
					</div>
				</section>
			</div>
    </Layout>
  )
}

export const query = graphql`
  fragment Thumbnail on File {
    childImageSharp {
      fluid(maxWidth: 500) {
        ...GatsbyImageSharpFluid_tracedSVG
      }
    }
  }
  query {
    site {
      siteMetadata {
        author
        description
        title
      }
    }
  }
`
